/* SHARED CSS */
$color-black: #090909;
$color-dark: #212121;
$color-purple: #625aa5;
$color-white: #ffffff;
$color-grey: #999999;
$color-gold: #d0aa69;
* {
  box-sizing: border-box; background-size: cover; background-position: center center; background-repeat: no-repeat;
  text-decoration: initial; outline: initial;
}
html{
  position: relative; height: 100%; width: 100%; transition: all ease 0.2s; overflow: hidden;
}
body {
  background-color: $color-dark; height: 100%; width: 100%; margin: initial;
}
p, a, h3{
  margin: initial; font-family: 'Source Sans Pro', sans-serif;
}
a{
  color: inherit; font-size: inherit;
}
h1, h2{
  margin: initial; font-family: 'Source Sans Pro', sans-serif; font-size: 24px; font-weight: 300; font-style: normal; letter-spacing: 2px;
}
::selection{
  /* WebKit/Blink Browsers */
  background: #1f1f1f; color: #fff;
}
::-moz-selection{
  /* Gecko Browsers */
  background: #1f1f1f; color: #fff;
}
.spacer1{
  clear: both; height: 50px; float: left; width: 100%;
}
.spacer2{
  clear: both; height: 100px; float: left; width: 100%;
}
.color-emphasize{
  color: #e20b00;
}
.color-dark{
  color: $color-dark;
}
.color-grey{
  color: $color-grey;
}
.color-pink{
  color: #f25f70;
}
.color-white{
  color: $color-white;
}
.color-black{
  color: $color-black;
}
.shared-textCenter{
  text-align: center;
}
.shared-leftFloat{
  float: left;
}
.shared-rightFloat{
  float: right;
}
.shared-whiteBg{
  background-color: $color-white !important;
}
.shared-blackBg{
  background-color: $color-black;
}
.shared-darkBg{
  background-color: $color-dark;
}
.shared-tranparentBg{
  background-color: transparent;
}
.hidden{
  display: none !important;
}
.shared-bodyContent{
  width: 100%; min-height: calc(100% - 250px); float: left; display: block;
}
// Parallax helper elements
$base-parallax-perspective: 8px;
@function parallax-scale-factor($z-distance) {
  @return ($base-parallax-perspective - $z-distance) / $base-parallax-perspective;
}
@function parallax-left-position($z-distance, $x-position) {
  @return parallax-scale-factor($z-distance) * $x-position;
}
@function parallax-top-position($z-distance, $y-position) {
  @return parallax-scale-factor($z-distance) * $y-position;
}
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}
.shared-parallaxContainer{
  position: relative; width: 100%; height: 100%; overflow-x: hidden; overflow-y: scroll;
  perspective: $base-parallax-perspective; perspective-origin: 0 0;
}
.shared-parallaxed{
  z-index: 0;
}


/* HEADER CLASSES */
header{
  display: block; width: 100%; position: relative; z-index: 100;
}
.header-innerWrapper{
  height: 170px; display: block; width: 100%; padding: 10px 20px; vertical-align: middle; background-color: #fff;
}
.header-sectionWrapper{
  width: calc(50% - 75px); display: table; float: left; height: 100%;
}
.header-section{
  width: calc(50% - 75px); display: table-cell; height: 100%; vertical-align: middle;
}
.header-sectionContent{
  font-size: 18px; text-align: center; font-weight: 300;
}
.header-logo{
  height: 100%; display: block; float: left;
}
.header-navigationWrapper{
  float: left; display: block; width: 100%; height: 50px; box-shadow: 0px 2px 1px 1px rgba(0,0,0,.15);
}
.header-buttonsWrapper{
  display: inline-block; font-size: 14px;
}
.header-button{
  display: inline-block; float: left; padding: 0 20px; height: 50px; transition: all ease 0.3s; line-height: 50px;
  font-weight: 600; font-style: normal; letter-spacing: 1.44px; font-size: 1em; position: relative;
  background-color: transparent; cursor: pointer; transition: all ease 0.2s;
}
.header-button:hover{
  color: #4f4f4f;
}
.header-button + .header-button::before{
  position: absolute; left: -2px; content: "\2022"; color: #ffa4ad; font-size: 1em; font-weight: 700;
  line-height: 50px; text-transform: uppercase;
}
.header-button.selected{
  color: #4f4f4f;
}
.header-buttonOutlined{
  line-height: 44px; color: #3c3b2a; border: 2px solid #3c3b2a; border-radius: 23px;
}
.header-buttonOutlined:hover{
  background-color: #3c3b2a; color: #efefef;
}
.header-buttonNude{
  background-color: transparent; color: #3c3b2a;
}
.header-buttonNude:hover{
  background-color: transparent; color: #3c3b2a;
}
.header-languageSelect{
  padding: 0 10px 0 20px;
}
.header-languageSelect + .header-languageSelect{
 padding: 0 10px;
}
.header-languageSelect + .header-languageSelect::before{
  position: absolute; left: -2px; content: "|"; color: #ffa4ad; font-size: 1em; font-weight: 700;
  line-height: 50px; text-transform: uppercase;
}
.header-languageSelect.selected{
  color: #4f4f4f;
}
.header-notificationWrapper{
  position: fixed; bottom: 20px; right: 20px; background-color: rgba(31, 31, 31, 0.9); width: 500px;
  border-radius: 2px; padding: 10px; margin-right: -550px; transition: all ease 0.5s; z-index: 10;
}
.header-notificationOpen{
  margin-right: 0px;
}
.header-notificationWrapper .header-sectionContent{
  font-size: 17px; font-weight: 300; text-align: left;
}
.header-inlineCTA{
  text-decoration: underline;
}
.header-notificationCTA{
  padding: 0 20px; background-color: #fff; color: #1f1f1f; font-size: 14px; border-radius: 2px; height: 40px;
  line-height: 40px; float: right; font-weight: 600; cursor: pointer; transition: all ease 0.2s; user-select: none;
}
.header-notificationCTA:hover{
  background-color: #E3E3E3;
}


/* FOOTER CLASSES */
footer{
  height: 200px; display: table; width: 100%; position: relative; background-color: $color-black;
}
.footer-innerWrapper{
  height: 100%; display: table-cell; width: 100%; padding: 20px; vertical-align: middle;
}
.footer-contentText{
  font-weight: 400; font-style: normal; font-size: 1em; letter-spacing: 2px; line-height: 1.2em;
}
.footer-contentText + .footer-contentText{
  margin-top: 10px;
}


/* HOMEPAGE CLASSES */
$logo-font-size: 75px; $letter-ratio-c1: 1.75; $letter-ratio-r: 2.88; $letter-ratio-a1: 1.98; $letter-ratio-f: 3.42; $letter-ratio-t: 2.96; $letter-ratio-e: 2.02;
$letter-ratio-d: 1.80; $letter-ratio-h: 1.48; $letter-ratio-a2: 1.90; $letter-ratio-c2: 2.14; $letter-ratio-k: 1.82;
$logo-size: $logo-font-size * (1 / $letter-ratio-c1 + 1 / $letter-ratio-r + 1 / $letter-ratio-a1 + 1 / $letter-ratio-f + 1 / $letter-ratio-t + 1 / $letter-ratio-e + 1 / $letter-ratio-d + 1 / $letter-ratio-h + 1 / $letter-ratio-a2 + 1 / $letter-ratio-c2 + 1 / $letter-ratio-k);
$desired-top: 25vw; $desired-left: 50%; $starting-offset: 0 - $logo-size / 2;
.homepage-parallaxedContent{
  position: absolute; transform-origin: 0 50%; height: 50px; line-height: 50px; font-size: $logo-font-size; left: 0px;
  user-select: none;
}
.homepage-bgImage{
  width: 100vw; height: initial;
}
.homepage-letter{
  z-index: 10; color: $color-white; font-family: "Source Sans Pro";
  &.homepage-c1{
    $perspective-position: 1px; $desired-left-offset: $starting-offset;
    left: calc(#{parallax-left-position(strip-unit($perspective-position), $desired-left)} + #{parallax-left-position(strip-unit($perspective-position), $desired-left-offset)}); top: parallax-top-position(strip-unit($perspective-position), $desired-top); transform: translateZ($perspective-position) scale(parallax-scale-factor(strip-unit($perspective-position)));
  }
  &.homepage-r{
    $letter-ratio: $letter-ratio-c1; $perspective-position: -2px; $desired-left-offset: ($starting-offset + ($logo-font-size / $letter-ratio)); $top-offset: 11px; $starting-offset: $desired-left-offset !global;
    left: calc(#{parallax-left-position(strip-unit($perspective-position), $desired-left)} + #{parallax-left-position(strip-unit($perspective-position), $desired-left-offset)}); top: calc(#{parallax-top-position(strip-unit($perspective-position), $desired-top)} + #{$top-offset}); transform: translateZ($perspective-position) scale(parallax-scale-factor(strip-unit($perspective-position)));
  }
  &.homepage-a1{
    $letter-ratio: $letter-ratio-r; $perspective-position: 3px; $desired-left-offset: $starting-offset + $logo-font-size / $letter-ratio; $top-offset: -7px; $starting-offset: $desired-left-offset !global;
    left: calc(#{parallax-left-position(strip-unit($perspective-position), $desired-left)} + #{parallax-left-position(strip-unit($perspective-position), $desired-left-offset)}); top: calc(#{parallax-top-position(strip-unit($perspective-position), $desired-top)} + #{$top-offset}); transform: translateZ($perspective-position) scale(parallax-scale-factor(strip-unit($perspective-position)));
  }
  &.homepage-f{
    $letter-ratio: $letter-ratio-a1; $perspective-position: -1px; $desired-left-offset: $starting-offset + $logo-font-size / $letter-ratio; $top-offset: 7.5px; $starting-offset: $desired-left-offset !global;
    left: calc(#{parallax-left-position(strip-unit($perspective-position), $desired-left)} + #{parallax-left-position(strip-unit($perspective-position), $desired-left-offset)}); top: calc(#{parallax-top-position(strip-unit($perspective-position), $desired-top)} + #{$top-offset}); transform: translateZ($perspective-position) scale(parallax-scale-factor(strip-unit($perspective-position)));
  }
  &.homepage-t{
    $letter-ratio: $letter-ratio-f; $perspective-position: -2px; $desired-left-offset: $starting-offset + $logo-font-size / $letter-ratio; $top-offset: 11px; $starting-offset: $desired-left-offset !global;
    left: calc(#{parallax-left-position(strip-unit($perspective-position), $desired-left)} + #{parallax-left-position(strip-unit($perspective-position), $desired-left-offset)}); top: calc(#{parallax-top-position(strip-unit($perspective-position), $desired-top)} + #{$top-offset}); transform: translateZ($perspective-position) scale(parallax-scale-factor(strip-unit($perspective-position)));
  }
  &.homepage-e{
    $letter-ratio: $letter-ratio-t; $perspective-position: 2px; $desired-left-offset: $starting-offset + $logo-font-size / $letter-ratio; $top-offset: -3.5px; $starting-offset: $desired-left-offset !global;
    left: calc(#{parallax-left-position(strip-unit($perspective-position), $desired-left)} + #{parallax-left-position(strip-unit($perspective-position), $desired-left-offset)}); top: calc(#{parallax-top-position(strip-unit($perspective-position), $desired-top)} + #{$top-offset}); transform: translateZ($perspective-position) scale(parallax-scale-factor(strip-unit($perspective-position)));
  }
  &.homepage-d{
    $letter-ratio: $letter-ratio-e; $perspective-position: -3px; $desired-left-offset: $starting-offset + $logo-font-size / $letter-ratio; $top-offset: 14.5px; $starting-offset: $desired-left-offset !global;
    left: calc(#{parallax-left-position(strip-unit($perspective-position), $desired-left)} + #{parallax-left-position(strip-unit($perspective-position), $desired-left-offset)}); top: calc(#{parallax-top-position(strip-unit($perspective-position), $desired-top)} + #{$top-offset}); transform: translateZ($perspective-position) scale(parallax-scale-factor(strip-unit($perspective-position)));
  }
  &.homepage-h{
    $letter-ratio: $letter-ratio-d; $perspective-position: -1px; $desired-left-offset: $starting-offset + $logo-font-size / $letter-ratio; $top-offset: 7.5px; $starting-offset: $desired-left-offset !global;
    left: calc(#{parallax-left-position(strip-unit($perspective-position), $desired-left)} + #{parallax-left-position(strip-unit($perspective-position), $desired-left-offset)}); top: calc(#{parallax-top-position(strip-unit($perspective-position), $desired-top)} + #{$top-offset}); transform: translateZ($perspective-position) scale(parallax-scale-factor(strip-unit($perspective-position)));
  }
  &.homepage-a2{
    $letter-ratio: $letter-ratio-h; $perspective-position: 3px; $desired-left-offset: $starting-offset + $logo-font-size / $letter-ratio; $top-offset: -7px; $starting-offset: $desired-left-offset !global;
    left: calc(#{parallax-left-position(strip-unit($perspective-position), $desired-left)} + #{parallax-left-position(strip-unit($perspective-position), $desired-left-offset)}); top: calc(#{parallax-top-position(strip-unit($perspective-position), $desired-top)} + #{$top-offset}); transform: translateZ($perspective-position) scale(parallax-scale-factor(strip-unit($perspective-position)));
  }
  &.homepage-c2{
    $letter-ratio: $letter-ratio-a2; $perspective-position: -2px; $desired-left-offset: $starting-offset + $logo-font-size / $letter-ratio; $top-offset: 11px; $starting-offset: $desired-left-offset !global;
    left: calc(#{parallax-left-position(strip-unit($perspective-position), $desired-left)} + #{parallax-left-position(strip-unit($perspective-position), $desired-left-offset)}); top: calc(#{parallax-top-position(strip-unit($perspective-position), $desired-top)} + #{$top-offset}); transform: translateZ($perspective-position) scale(parallax-scale-factor(strip-unit($perspective-position)));
  }
  &.homepage-k{
    $letter-ratio: $letter-ratio-c2; $perspective-position: 1px; $desired-left-offset: $starting-offset + $logo-font-size / $letter-ratio; $top-offset: 0px; $starting-offset: $desired-left-offset !global;
    left: calc(#{parallax-left-position(strip-unit($perspective-position), $desired-left)} + #{parallax-left-position(strip-unit($perspective-position), $desired-left-offset)}); top: calc(#{parallax-top-position(strip-unit($perspective-position), $desired-top)} + #{$top-offset}); transform: translateZ($perspective-position) scale(parallax-scale-factor(strip-unit($perspective-position)));
  }
}
.homepage-parallaxedWrapper{
  position: absolute; transform-origin: 0 50%; z-index: 10;
  &#intro{
    $perspective-position: 1px; $desired-left-offset: 0vw; $desired-top: 165vh;
    left: calc(#{parallax-left-position(strip-unit($perspective-position), $desired-left-offset)}); top: parallax-top-position(strip-unit($perspective-position), $desired-top); transform: translateZ($perspective-position) scale(parallax-scale-factor(strip-unit($perspective-position)));
  }
  &#second{
    $perspective-position: 5px; $desired-left-offset: 0vw; $desired-top: 385vh;
    left: calc(#{parallax-left-position(strip-unit($perspective-position), $desired-left-offset)}); top: parallax-top-position(strip-unit($perspective-position), $desired-top); transform: translateZ($perspective-position) scale(parallax-scale-factor(strip-unit($perspective-position)));
  }
}
.homepage-parallaxedInnerWrapper{
  position: relative; float: left; padding: 60px 40px;
  width: 100vw; background-color: $color-purple; min-height: 300px;
  // clip-path: polygon(0px 0, 100% 40px , 100% 100%, 0% calc(100% - 40px));
  clip-path: polygon(0px 0, 100% 40px , 100% 100%, 0% 100%);
}
.homepage-parallaxedContentWrapper{
  position: relative; float: left; display: block; z-index: 10;
}
.homepage-sectionTitle{
  font-weight: 500; font-size: 30px; color: $color-white; text-align: left;
  display: inline-block; position: relative;
  +.homepage-sectionContent{
    margin-top: 40px;
  }
}
.homepage-sectionContent{
  font-weight: normal; font-size: 18px; color: $color-white; text-align: left; position: relative;
  text-align: justify;
}
.homepage-sectionWrapper{
  width: 100%; display: block; float: left; position: relative; padding: 10vw 20vw 0 20vw;
  overflow: hidden; min-height: 400px; z-index: 10; background: $color-dark;

  &#homepage-firstSection{
    z-index: 5;
    background: $color-black; background: linear-gradient(180deg, rgba(9,9,9,0) 0%, rgba(9,9,9,0.9) 81%, rgba(9,9,9,1) 100%);
  }
}
.homepage-sectionBackground{
  position: absolute; top: 0; left: 0; width: 100%; object-fit: cover;
}
.homepage-sectionForeground{
  position: relative; float: left; width: 100%; height: 100%; z-index: 10; padding: 2vw; background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.95) 30%, rgba(255,255,255,1) 100%);
  border-radius: 2px;
}
.homepage-sectionForeground h1, .homepage-sectionForeground h2{
  display: block; line-height: 1.2em;
}
.homepage-sectionForeground h1{
  margin-bottom: 20px;
}
.homepage-sectionForeground h2{
  margin-bottom: 10px;
}
.homepage-sectionContentText{
  display: block; font-style: normal; font-size: 18px; line-height: 1.5em; margin-top: 35px; font-weight: 300;
  text-align: justify;
}
.homepage-sectionContentText b{
  font-weight: 600;
}
.homepage-sectionContentText + .homepage-sectionContentText{
  margin-top: 15px;
}
.homepage-subsectionWrapper{
  display: table-cell; width: 100%; height: 100%; vertical-align: middle;
}
.homepage-inlineCTA{
  color: #34862d;
}
.homepage-icon{
  height: 24px; width: 24px;
}
.homepage-sectionLeft, .homepage-sectionRight{
  display: inline-block; float: left; height: 350px; padding: 0 10px;
}
.homepage-sectionLeft{
  width: 50%;
}
.homepage-sectionRight{
  width: 50%;
}


/* TEST PAGE */
.parallaxedWrapper{
  height: 100vh; width: 100vw; background-color: #fff; display: block; position: relative;
  background-attachment: fixed; background-size: cover; background-position: center center;
  background-repeat: no-repeat;
}


/* OVERLOADS */
.main-only, .mobile-only{
  display: none;
}
